import React from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';
import { loadFull } from 'tsparticles';
import { useCallback, useMemo } from 'react';

const ParticlesComponent = props => {
  const options = useMemo(() => {
    return {
      fullScreen: {
        enable: true,
        zIndex: -10,
      },
      detectRetina: true,
      //Particles
      particles: {
        number: { limit: 13 },

        links: {
          consent: false,
          distance: 500,
          enable: true,
          frequency: 1,
          opacity: 0.3,
          shadow: {
            blur: 10,
            color: {
              value: '#000',
            },
            enable: true,
          },
          triangles: {
            enable: false,
            frequency: 1,
          },

          width: 1.5,
          warp: false,
        },
        color: {
          value: '#FFFFFF',
        },

        move: {
          enable: true, //enabling this will make particles move in the canvas
          speed: { min: 0.1, max: 0.1 },
        },
        opacity: {
          random: {
            enable: true,
          },
          value: { min: 0.1, max: 0.6 },
        },
        size: {
          value: { min: 1, max: 6 }, //randomize size of particles
        },
      },

      //Interactivity
      interactivity: {
        connect: {
          distance: 500,
          links: {
            opacity: 0.1,
          },
          radius: 1,
        },

        events: {
          onHover: {
            enable: true,
            mode: 'attract',
          },
        },
      },

      responsive: [
        {
          maxWidth: 500,
          options: {
            particles: {
              number: { limit: 4 },
              opacity: {
                random: {
                  enable: true,
                },
                value: { min: 0.1, max: 0.1 },
              },
              links: {
                consent: false,
                distance: 500,
                enable: true,
                frequency: 1,
                opacity: 0.2,
                shadow: {
                  blur: 10,
                  color: {
                    value: '#000',
                  },
                  enable: false,
                },
                triangles: {
                  enable: false,
                  frequency: 1,
                },

                width: 1.5,
                warp: false,
              },
              size: {
                value: { min: 0.5, max: 1 }, //randomize size of particles
              },
            },
            interactivity: {
              connect: {
                distance: 500,
                links: {
                  opacity: 0.1,
                },
                radius: 1,
              },

              events: {
                onHover: {
                  enable: true,
                  mode: 'attract',
                },
              },
            },
          },
        },
      ],
      detectRetina: true,
    };
  }, []);

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    await loadSlim(engine);
    //loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

  return (
    <Particles id={props.id} init={particlesInit} options={options} loaded={particlesLoaded} />
  );
};

export default ParticlesComponent;
